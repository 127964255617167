import { Component, OnInit , Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {CallService} from '../../../services/call.service';
import {NotificationService} from '../../../services/notification.service';
import {AuthService} from '../../../services/auth.service';
import { PageEvent } from '@angular/material/paginator';
import { TableService } from '../../../services/pager';
import { MatTableDataSource } from '@angular/material/table';
import {SpinnerService} from '../../../services/spinner.service';

@Component({
  selector: 'app-address-popup',
  templateUrl: './address-popup.component.html',
  styleUrls: ['./address-popup.component.css']
})
export class AddressPopupComponent implements OnInit {

  addressSelected: any;
  addresses : any = [];
  items : any = [];

  displayedColumns: string[] = ['adress','actions'];
  dataSource: any;
  empty = false;

  constructor(private spinner : SpinnerService, public auth : AuthService, private notify : NotificationService,private call : CallService, 
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddressPopupComponent>,public  tableService: TableService<any>) { }

  ngOnInit(): void {

    this.tableService.pager.pageSize = 10 ; 
    
    this.list();


  }

  list(event?: PageEvent){

    this.spinner.open();

    let httpParams = this.call.buildRequestParams(this.tableService.sort,"m",
    {pageIndex: event ? event.pageIndex : this.tableService.pager.pageIndex , pageSize: event ? event.pageSize : this.tableService.pager.pageSize})

    this.call.get(this.call.HOST + "/adress/customer/" + this.auth.getCustomer() , httpParams).subscribe(response =>{

      this.items = [];
      for(let r of response.result){
        if( r.status == 1){ // SOLO SI LA DIRECCION SE ENCUENTRA ACTIVA
          this.items.push(r);
        }
      }

      if(this.items.length == 0){
        this.empty = true;
      }


      this.dataSource = new MatTableDataSource<any>(this.items);
      this.tableService.pager = response.pager;
      this.tableService.selection.clear();

      this.spinner.close();

    
      
    }, err=>{
      this.spinner.close();
      this.notify.showError(err)
    });

  }

  select(row : any) {
    this.dialogRef.close(row);
  }

}
