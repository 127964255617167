<ul>
       
        <li>
            <a  id="subs" routerLink="/my-subscriptions">Mis Suscripciones</a> 
        </li>
        <li>
            <a id="address" routerLink="/addresses">Mis Direcciones
            </a>
        </li>
        <li>
            <a  id="cards" routerLink="/my-cards">Mis Tarjetas
            </a>
        </li>
        
</ul>