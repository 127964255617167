import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import {AuthService} from '../../services/auth.service';
import {NotificationService} from '../../services/notification.service';
import {CallService} from '../../services/call.service';
import { TableService } from '../../services/pager';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import {UpdateSubPopupComponent} from './update-sub-popup/update-sub-popup.component';
import {SpinnerService} from '../../services/spinner.service';

@Component({
  selector: 'app-my-subscriptions',
  templateUrl: './my-subscriptions.component.html',
  styleUrls: ['./my-subscriptions.component.css']
})
export class MySubscriptionsComponent implements OnInit {

  displayedColumns: string[] = ['plan.title', 'plan.feeCost_plan', 'plan.frecuency.name_frecuency' ,'next_bill_date','status_customerPlan', 'actions'];
  dataSource: any;
  tarjetas : any = [];
  items: Array<any> = [];

  constructor(private spinner : SpinnerService, private dialog: MatDialog,private call : CallService,public auth : AuthService, private notify : NotificationService,public  tableService: TableService<any>) {
  }

  ngOnInit(): void {
    this.tableService.pager.pageSize = 10 ; 
    this.list();
  }


  list(event?: PageEvent){

    this.spinner.open();

    let httpParams = this.call.buildRequestParams(this.tableService.sort,"m",
    {pageIndex: event ? event.pageIndex : this.tableService.pager.pageIndex , pageSize: event ? event.pageSize : this.tableService.pager.pageSize});

    this.call.get(this.call.HOST + "/hasplan/customer/" + this.auth.getCustomer() + "/service/" + this.auth.getService(), httpParams).subscribe(response =>{
      for(let sus of response.result){
        sus.status = sus.status_customerPlan;
        switch (sus.status_customerPlan){
          case 1 : sus.status_customerPlan = "Activa";
                    break;
          case 2 : sus.status_customerPlan = "Inactiva";
                    break;
          case 3 : sus.status_customerPlan = "Congelada";
                  break;
          case 4 : sus.status_customerPlan = "En proceso de cobro";
                  break;
          case 5 : sus.status_customerPlan = "No se pudo cobrar";
                  break;
        }
      }

      this.dataSource = new MatTableDataSource<any>(response.result);
        this.tableService.pager = response.pager;
        this.tableService.selection.clear();
        this.spinner.close();

      
    }, err =>{
      this.notify.showError(err);
      console.log(err);
      this.spinner.close();
    })
  }

  update( row : any){
    let dialogRef: MatDialogRef<any> = this.dialog.open(UpdateSubPopupComponent, {
      width: '70%',
      height: '60%',
      disableClose: true,
      data: { sub : row}
    })
      dialogRef.afterClosed()
      .subscribe(res => {

        if(res == 1){
          this.notify.showSuccess("Suscripción pausada correctamente");
        }else if(res == 2){
          this.notify.showSuccess("Suscripción cancelada correctamente");
        } else if(res == 0){
          this.notify.showSuccess("Suscripción activada correctamente");
        }

        this.list();
          
      })
  }

  

}
