<!-- MENU DE LA APP -->
<app-menu></app-menu>

<div class="container">
    <app-static-banner></app-static-banner>
    <div class="dashboard">

        <div class="column-profile">
            <app-profile-menu></app-profile-menu>
        </div>
            
        <div class="column-data">
            <div class="content-data">
            <h1>Mis Datos</h1><br>
            <form [formGroup]="form" (ngSubmit)="update()" class="form-horizontal">
                <fieldset>

                <!-- Form Name -->
                <legend>Mi perfil</legend>

                <!-- Text input-->
                <div class="form-group">
                <label class="col-md-4 control-label" for="textinput">Nombre</label>
                <div class="col-md-5">
                <input [formControl]="form.controls['name_customer']" id="textinput" name="textinput" type="text"  class="form-control input-md">

                </div>
                </div>

                <!-- Text input-->
                <div class="form-group">
                <label class="col-md-4 control-label" for="textinput">Apellido</label>
                <div class="col-md-5">
                <input  [formControl]="form.controls['lastName_customer']" id="textinput" name="textinput" type="text"  class="form-control input-md">

                </div>
                </div>

                <!-- Text input-->
                <div class="form-group">
                <label class="col-md-4 control-label" for="textinput">Cédula</label>
                <div class="col-md-5">
                <input [formControl]="form.controls['identifier_customer']" id="textinput" name="textinput" type="text"  class="form-control input-md" required="">

                </div>
                </div>

                <!-- Text input-->
                <div class="form-group">
                <label class="col-md-4 control-label" for="email">Correo</label>
                <div class="col-md-5">
                <input [formControl]="form.controls['email_customer']" id="email" name="email" type="text"  class="form-control input-md" required="">

                </div>
                </div>

                <!-- Text input
                <div class="form-group">
                <label class="col-md-4 control-label" for="number">Teléfono</label>
                <div class="col-md-5">
                <input id="number" name="number" type="text" class="form-control input-md">

                </div>
                </div>-->

                <!-- Button -->
                <div class="form-group">
                <label class="col-md-4 control-label" for="update"></label>
                <div class="col-md-4">
                <button  [disabled]="form.invalid" id="update" name="update" class="btn btn-primary">Actualizar</button>
                </div>
                </div>

                </fieldset>
            </form>

            </div>
        </div>
    </div>
</div>