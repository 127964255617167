import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-recover-popup',
  templateUrl: './recover-popup.component.html',
  styleUrls: ['./recover-popup.component.css']
})
export class RecoverPopupComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<RecoverPopupComponent>) { }

  ngOnInit(): void {
  }

  exit() {
    this.dialogRef.close();
  }

}
