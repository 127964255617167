<app-menu></app-menu>
<div class="terminos">
    <h2>Terminos y  Condiciones</h2>
    <p>El costo de la suscripción de Wine Box (plan “Essentials”) es de $35. El cobro se  
        ejecuta autámaticamente todos los meses,  hasta que el cliente  cancele su 
        suscripción.  El cliente puede cancelar o pausar su sucripción en cualquier 
        momento, ingresando a su cuenta: Mis sucripciones – actualizar .  </p>
      <p>   
        Club de vinos (plan “Signature”) puede comprarse en dos modalidades.  Con un 
        único cobro anual de $300, con vencimiento anual.  O, con cargos bimestrales de 
        $50.  Bajo esta modalidad, los cobros se ejecutan cada dos meses hasta que el 
        cliente cancele su suscripción.  El cliente puede cancelar o pausar su sucripción 
        en cualquier momento, ingresando a su cuenta: Mis sucripciones – actualizar . <br>
      </p>  
      <p> 
        El envío a domicilio está incluido dentro del costo del plan. *No incluye el ITBMS  
        Los envíos de Wine Box se hacen todos los meses.  Los envíos  de Club de Vinos 
        se realizan, los últimos días de los meses de enero, marzo, mayo, julio, 
        septiembre y noviembre. <br>
    </p>   <p>  
        Ambas suscripciones están disponible, con envíos a domicilio en la ciudad de 
        Panamá,  área metropolitana.  Ofrecemos también la opción de retiro en nuestras 
        sucursales de Costa Verde,  Coronado, Buenaventura, David y Boquete.  <br>
         
    </p>  
    <p> 
        El beneficio de catas gratuitas es exclusivo para catas en Felipe Motta Wine Store & Deli, 
        no aplica para cenas maridajes. No incluye redimible por los vinos catados durante 
        el evento. Sujeto a disponibilidad, deben reservar cupo al correo <br>
        clubdevinos@felipemotta.com 
    </p> <p>  
         
         Si tienes alguna pregunta sobre tu suscripción y/o los envíos escríbemos a: 
        clubdevinos@felipemotta.com </p>
</div>