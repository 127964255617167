import { Component } from '@angular/core';
import {OnInit, AfterViewInit, Renderer2 } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'f-motta-portal';

  constructor(  private router: Router ){

    this.router.events.subscribe(event => {
      if(event instanceof NavigationEnd){
          gtag('config', 'G-WYJHHFVZVW', 
                {
                  'page_path': event.urlAfterRedirects
                }
               );
       }
    });
    
  }
}
