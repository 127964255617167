import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import {CallService} from '../../services/call.service';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.css']
})
export class ConfirmationComponent implements OnInit {

  public email : any;
  public confirmation : any;
  public plan : any;
  public title : any;

  constructor(private router : Router, private activateRoute : ActivatedRoute) { }

  ngOnInit(): void {
    this.activateRoute.queryParams.subscribe(params =>{
      this.email = params.email;
      this.confirmation = params.confirmation;
    })
  }

}
