import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-profile-menu',
  templateUrl: './profile-menu.component.html',
  styleUrls: ['./profile-menu.component.css']
})
export class ProfileMenuComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document
  ) { }

  ngOnInit(): void {
    this.route.url.subscribe(params =>{

      var path = params[0].path;
      if(path === "profile"){
        this.document.getElementById('profile')?.classList.add('active');
      }else if( path === "my-subscriptions"){
        this.document.getElementById('subs')?.classList.add('active');
      }else if(path === "addresses"){
        this.document.getElementById('address')?.classList.add('active');
      }else if(path === "my-cards"){
        this.document.getElementById('cards')?.classList.add('active');
      }else{
        this.document.getElementById('reset')?.classList.add('active');
      }
      
    })
  }

}
