import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import {CallService} from '../../services/call.service';
import {AuthService} from '../../services/auth.service';
import {NotificationService} from '../../services/notification.service';

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.css']
})
export class ResetComponent implements OnInit {

  form: FormGroup ;

  constructor(private call : CallService,private auth : AuthService, private notify : NotificationService) {
    this.form = new FormGroup({
      actual_password: new FormControl('', [Validators.required]),
      new_password: new FormControl('', [Validators.required,Validators.minLength(6)]),
      newPasswordConfirm: new FormControl('', [Validators.required,Validators.minLength(6)])
    });
   }

  ngOnInit(): void {
  }

  change(){

    const form = this.form.value;
    if(form.new_password != form.newPasswordConfirm){
      this.notify.showError("Nueva contraseña NO coincide");
      return;
    }
    // BUSCAMOS EL AUTH USER
    this.call.get(this.call.HOST + '/user/ecommerce/' + this.auth.getUser()).subscribe( user =>{

      if(user.result.user.authUser.id === null){
        this.notify.showError("No se puede cambiar contraseña, consultar administrador - AuthUser problem");
        return;
      }
      // CAMBIAMOS EL PASSWORD
      this.call.post(form, this.call.HOST + "/ecommerce/users/" + user.result.user.authUser.id + "/changePassword").subscribe(response =>{
        this.notify.showSuccess("Contraseña actualizada correctamente");
      }, err =>{
        this.notify.showError(err);
      })

    }, err =>{
      this.notify.showError(err);
    })




  }

}
