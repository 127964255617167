import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../services/auth.service';
import { Router, ActivatedRoute } from "@angular/router";
import { DOCUMENT } from '@angular/common';
import { Inject } from '@angular/core';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  public loggedIn : Boolean = false;

  constructor(private auth : AuthService,private router: Router,
    private activatedRoute: ActivatedRoute, @Inject(DOCUMENT) private document: Document) { }

  ngOnInit(): void {
    this.loggedIn = this.auth.isLoggedIn();
  }

  logout(){
    // CERRAMOS LA SESION
    this.auth.closeSesion();
    window.location.href = "/";
  }

  show(){
    // @ts-ignore: Object is possibly 'null'.
    this.document.getElementById('id01')?.style.display='block';
  }

}
