import { Component, OnInit, AfterViewInit,  Renderer2, ElementRef } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { HttpParams} from '@angular/common/http';
import {CallService} from '../../services/call.service';
import { Inject } from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {NotificationService} from '../../services/notification.service';
import { DOCUMENT } from '@angular/common';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { RecoverPopupComponent } from './recover-popup/recover-popup.component';
import { DataCustomerClass } from 'src/app/class/data-customer-class.model';
import { CreateCustomerClass } from 'src/app/class/create-customer-class.model';
import {SpinnerService} from '../../services/spinner.service';
import jwt_decode from "jwt-decode";
import { environment } from '../../../environments/environment';

declare const google:any;


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, AfterViewInit  {

  // socialUser!: SocialUser;
  isLoggedin?: boolean;

  

  signinForm: FormGroup ;
  signupForm: FormGroup ;
  recoverForm: FormGroup ;
  httpOptions : any;
  errorNumber = true;
  numberRequired = false;

  isGoogle : boolean = false;
  signinFromGoogle : boolean = false;


  options : any;
  
  constructor(
    // private socialAuthService: SocialAuthService,
    private spinner: SpinnerService,
    private dialog: MatDialog,
    private call: CallService,
    private auth: AuthService,
    private notify: NotificationService,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2, private el: ElementRef
  ){

    this.signinForm = new FormGroup({
      username: new FormControl('', [Validators.required,Validators.email]),
      password: new FormControl('', Validators.required)
    });

    this.recoverForm = new FormGroup({
      username: new FormControl('', [Validators.required,Validators.email])
    });

    this.signupForm = new FormGroup({
      name_customer: new FormControl('', Validators.required),
      lastName_customer: new FormControl('', Validators.required),
      email_customer: new FormControl('', [Validators.required,Validators.email])
    });
   }

  ngOnInit(): void { 
   
    this.options = {initialCountry: '',separateDialCode:true,preferredCountries: ['us','pa']};

    // Get the modal
    let modal = document.getElementById('id01') ;
    // When the user clicks anywhere outside of the modal, close it
    if(!this.auth.isLoggedIn() && window.location.pathname === "/subscription"){
     // NO SE HACE NADA
    }else{
      window.onclick = function(event) {
       // if(window.location.pathname !== "/subscription"){
          if (event.target == modal) {
            // @ts-ignore: Object is possibly 'null'.
            modal.style.display = "none" ;
       //   }
          
        }    
      }
    }

  }
      
  ngAfterViewInit(): void {
    this.loadGoogleScript();
    this.checkGoogleLibraryAndRenderButton();
  }


  loadGoogleScript() {
    const script = this.renderer.createElement('script');
    this.renderer.setAttribute(script, 'src', 'https://accounts.google.com/gsi/client');
    this.renderer.setAttribute(script, 'async', '');
    this.renderer.setAttribute(script, 'defer', '');
    this.renderer.appendChild(this.el.nativeElement.ownerDocument.head, script);
  }

  checkGoogleLibraryAndRenderButton(): void {
     if (typeof google === 'undefined') {
      setTimeout(() => {
        this.checkGoogleLibraryAndRenderButton();
      }, 100);
    } else {
      this.renderGoogleButton();
    }
  }

  renderGoogleButton(): void {
    this.isGoogle = true;
     google.accounts.id.initialize({
      client_id: environment.clientID,
      callback: this.searchGoogle.bind(this),
    });

    google.accounts.id.renderButton(
      document.getElementById("buttonDiv"),
      { theme: "outline", size: "short", callback:"Login with Google", shape:"pill" }  // customization attributes
    );
    
   // google.accounts.id.prompt()
   
  }


  getCheckEnviroment() {
    if (environment.production) {
      console.log("prod");
      console.log(environment.clientID);
    } else {
      console.log("dev");
      console.log(environment.clientID);
    }
  }


  searchGoogle(response:any){
    const user: any = jwt_decode(response.credential);
    this.signupWithGoogle(user);
  }
 

  // PARA CAMBIAR LA VISTA DE INICIAR SESION A RECUPERAR CONTRASE*A
  change(){
   let dialogRef: MatDialogRef<any> = this.dialog.open(RecoverPopupComponent, {
    width: '70%',
    height: '60%',
    disableClose: true,
    data: { }
  })
    dialogRef.afterClosed()
    .subscribe(res => {

      console.log("SE CERRO EL POPUP");
        
    })
    
  }


  // PARA MANEJAR EL REGISTRO DE USUARIOS USANDO GOOGLE 
  signupWithGoogle( user : any){
    this.spinner.open();
    var signupData : any = {};

    signupData.email_customer = user.email;
    signupData.name_customer = user.name;
    signupData.identifier_customer = user.email;
    signupData.lastName_customer = user.name;

    let from = "GOOGLE";
    this.signinFromGoogle = true;

    var data = new DataCustomerClass(signupData.email_customer, signupData.name_customer, from);

    var dataFM = new CreateCustomerClass(data);

    // COLOCAMOS ESTATUS E ACTIVO AL CLIENTE
    signupData.status_customer = 1;
    // COMO ES CLIENTE DE LANDING, SU EMAIL SERA EL CODIGO DEL CLIENTE
    signupData.code_customer = signupData.email_customer;

    signupData.dataFM = dataFM;

      // ESTE ES EL PARTNER QUE ES DUE*O DEL PLAN Y SERVICIO
      signupData.partner = {id: this.auth.getPartner()};
      signupData.service = Number(this.auth.getService());

     this.call.post(signupData, this.call.HOST + "/ecommerce/customer/registry/fmotta/google").subscribe(response =>{
        this.signinForm.controls["username"].setValue(response.customer.email_customer);
        this.signinForm.controls["password"].setValue(response.accessToken);
        localStorage.setItem('google', "1");
        this.signin();
      }, err =>{
        this.spinner.close();
        this.notify.showError(err);
      });
    
  }


  // PARA REALIZAR PROCESO DE LOGIJ
  signin() {

    this.spinner.open();

    const signinData = this.signinForm.value;

    let params = new HttpParams() ;
   
		params = params.set('grant_type', 'fmotta');
		params = params.set('username', signinData.username);
		params = params.set('password', signinData.password);
		params = params.set('client_id', 'web_site');
    params = params.set('partner_id', this.auth.getPartner());
    params = params.set('google', this.signinFromGoogle);

    // SOLICITAMOS TOKEN
    this.call.auth(this.call.HOST + "/oauth/token",params,this.httpOptions).subscribe( res =>{

      localStorage.setItem('token', res.access_token);
      localStorage.setItem('scope', res.scope);

      this.call.get(this.call.HOST + '/user/ecommerce/' + res.user_id).subscribe(user =>{

        this.spinner.close();

        if(user.result.user.status_user == 1){ // SOLO LO DEJAMOS ENTRAR SI ESTA ACTIVO
          // TODO - ESTO PUEDE NO SER NECESARIO
    
            // GUARDAMOS EL ID DEL USER
           localStorage.setItem('user', user.result.user.id);
           localStorage.setItem('customer', user.result.customer.id);
           let time = new Date(); // MOMENTO DEL LOGIN
           let expDate = new Date(time.setMinutes(time.getMinutes() + this.auth.APP_TIME_SESSION))
           localStorage.setItem(this.auth.APP_USER_TIME, expDate.toString());
           

            // COMPROBAMOS DE DONDE ESTA INICIANDO SESION EL USUARIO
           if(window.location.pathname !== "/subscription"){ // MIENTRAS NO SEA DESDE LA SUSCRIPCION RETORNAMOS LA NAVEGACION AL HOME
            this.notify.showSuccess("Ha iniciado sesion correctamente");
              window.location.href = "/";
            }else{  // SI ESTA INICIANDO SESION DESDE LA SUSCRIPCION ENTONCES SIMPLEMENTE QUITAMOS LA VISTA
              this.notify.showSuccess("Ha iniciado sesion correctamente");
              // @ts-ignore: Object is possibly 'null'.
              this.document.getElementById('id01')?.style.display='none';
              
              // window.location.reload();
            }

           
           
           }else{
             this.notify.showError("Lo sentimos, su usuario se encuentra inactivo");
           }

      }, err =>{
        this.spinner.close();
        console.log(err);
        this.notify.showError(err);
      })

    }, err =>{
      this.spinner.close();
      console.log(err);
      this.notify.showError(err);
    })

     
  }
  // PARA MANEJAR EL REGISTRO DE USUARIOS
  signup(){
    this.spinner.open();
    
    const signupData = this.signupForm.value;

    let from = "AUTOGEN";
    this.signinFromGoogle = false;

    var data = new DataCustomerClass(signupData.email_customer, signupData.name_customer + " " + signupData.lastName_customer,from);

    var dataFM = new CreateCustomerClass(data);

    
   
    // COLOCAMOS ESTATUS E ACTIVO AL CLIENTE
    signupData.status_customer = 1;
    // COMO ES CLIENTE DE LANDING, SU EMAIL SERA EL CODIGO DEL CLIENTE
    signupData.code_customer = signupData.email_customer;
    signupData.identifier_customer = signupData.email_customer;;

    signupData.dataFM = dataFM;

    // BUSCAMOS LA INFORMACION DEL SERVICIO
   // this.call.get(this.call.HOST + "/ecommerce/service/" + this.auth.getService()).subscribe( response =>{

      // ESTE ES EL PARTNER QUE ES DUE*O DEL PLAN Y SERVICIO
      signupData.partner = {id: Number(this.auth.getPartner())};
      signupData.service = Number(this.auth.getService());

    this.call.post(signupData, this.call.HOST + "/ecommerce/customer/registry/fmotta").subscribe(response =>{
        this.spinner.close();
        localStorage.setItem('google', "0");
        this.signinForm.controls["username"].setValue(response.customer.email_customer);
        this.signinForm.controls["password"].setValue(response.accessToken);
        this.signin();
      }, err =>{
        this.spinner.close();
        this.notify.showError(err);
      }); 

   // }, err =>{
    //  this.notify.showError(err);
    //}); 
    
  }
  // PARA PROCESO DE RECUPERAR CONTRASE*A
  recoverPassword(){
    const recoverData = this.recoverForm.value;
    this.call.get(this.call.HOST + "/auth/" + recoverData.username).subscribe(response =>{
      this.notify.showSuccess("Por favor ingresa el password enviado a tu correo");
      this.signinForm.controls["username"].setValue(recoverData.username);
      this.call.get(this.call.HOST + "/auth/recover/" + recoverData.username + "/" + this.auth.getService()).subscribe(response=>{
         
      }, err =>{
        this.notify.showError(err);
      });
    }, err =>{
      this.notify.showError("Email no registrado, por favor crea tu cuenta...");
      //this.addClass() // mostramos panel de registro...
      //this.document.getElementById('id02')?.focus();
    })

  }
  // FUNCIONES NECESARIAS PARA INPUT DE TEL

  hasError(obj:any){
    // FALSE : ERROR - TRUE -> NO ERROR
    console.log(obj);
    this.errorNumber = obj;
    if(obj){
      this.numberRequired = true;
      console.log(this.numberRequired);
    }
  }

  onCountryChange(obj:any){
    //console.log(obj);
  }

  telInputObject(obj:any){
    //console.log(obj);
  }

  getNumber(obj:any){
    this.signupForm.controls["phoneNumber_customer"].setValue(obj);
  }

  addClass(){
    this.document.getElementById('container')?.classList.add('right-panel-active')
  }

  removeClass(){
    this.document.getElementById('container')?.classList.remove('right-panel-active');
  }
  // Esta función cierra el popup al hacer clic en el botón de cierre
  closePopup() {
    const modal = document.getElementById('id01');
    if (modal) {
      modal.style.display = 'none';
    }
  }

}
