import { Injectable } from "@angular/core";
import { HttpHeaders } from '@angular/common/http';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest
} from "@angular/common/http";
import { Observable } from "rxjs";
//import { JwtAuthService } from "../services/auth/jwt-auth.service";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(/*private jwtAuth: JwtAuthService*/) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    // BUSCAMOS EL TOKEN DE ACCESO DEL USUARIO
    var token = localStorage.getItem('token');
    var changedReq;
    var user = -1 ;
    // SI HAY UN USUARIO EN SESION ENTONCES MANDAMOS SU ID
    if(localStorage.getItem('user') != null){
      user = Number(localStorage.getItem('user'));
    }
    // SI HAY TOKEN ENTONCES HAY USUARIO
    if ( token != null ) {
      changedReq = req.clone({
        headers: new HttpHeaders({
          'Authorization': token,
          'User': user.toString(),
        })
      });
    
    } else { // EN CASO DE QUE NO HAYA NADA NO SE MODIFICA EL REQUEST
     
      changedReq = req;
      
    }
    return next.handle(changedReq);
  }
}
