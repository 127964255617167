<!-- MENU DE LA APP -->
<app-menu></app-menu>

<div class="container">

    <div class="text-head">
        <h1>Recibirás tus vinos acorde al plan elegido.</h1>
    </div>

<div class="content-plan">
    <div class="content-form">
        <div class="process">
        <h1><b>3</b>Confirmación  </h1><hr>
            </div>
<div class="success">
<p>Felicidades, tu orden ha sido confirmada con el siguiente código:<b> #{{ confirmation }}</b> Recibirás un correo a <b>{{ email }}</b> con tu confirmación y más detalles acerca de tu suscripción.</p>
<div class="thanks"><p>Gracias por confiar en nosotros, salud!</p></div>

</div>

    </div></div>

    
               

</div>