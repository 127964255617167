<!-- MENU DE LA APP -->
<app-menu></app-menu>

<div class="container">

    <div class="text-head">
        <h1>404 - Página NO encontrada.</h1>
    </div>

    <!--login-->
    <app-login></app-login>

</div>