import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeComponent } from './views/home/home.component';
import { WineCatalogComponent } from './components/wine-catalog/wine-catalog.component';
import { MenuComponent } from './components/menu/menu.component';
import { SubscriptionComponent } from './views/subscription/subscription.component';
import { ProfileComponent } from './views/profile/profile.component';
import { MySubscriptionsComponent } from './views/my-subscriptions/my-subscriptions.component';
import { ProfileMenuComponent } from './components/profile-menu/profile-menu.component';
import { AddressesComponent } from './views/addresses/addresses.component';
import { CreditcardsComponent } from './views/creditcards/creditcards.component';
import { ResetComponent } from './views/reset/reset.component';
import { ConfirmationComponent } from './views/confirmation/confirmation.component';
import { LoginComponent } from './components/login/login.component';
import { StaticBannerComponent } from './components/static-banner/static-banner.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { AuthGuard } from './guards/auth.guard';
import { NotFoundComponent } from './views/not-found/not-found.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { UnauthorizedComponent } from './views/unauthorized/unauthorized.component';
import { ToastrModule } from 'ngx-toastr';
import {Ng2TelInputModule} from 'ng2-tel-input';
import { FacPopupComponent } from './views/subscription/fac-popup/fac-popup.component';
import {MatDialogModule} from '@angular/material/dialog';
import { TdcPopupComponent } from './views/popups/tdc-popup/tdc-popup.component';
import {  MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import { CreatePopupComponent } from './views/addresses/create-popup/create-popup.component';
import { EditPopupComponent } from './views/addresses/edit-popup/edit-popup.component';
import { CreateTdcComponent } from './views/creditcards/create-tdc/create-tdc.component';
import { UpdateSubPopupComponent } from './views/my-subscriptions/update-sub-popup/update-sub-popup.component';
import { AddressPopupComponent } from './views/popups/address-popup/address-popup.component';
import { ValidatePopupComponent } from './views/my-subscriptions/update-sub-popup/validate-popup/validate-popup.component';
import { RecoverPopupComponent } from './components/login/recover-popup/recover-popup.component';

import { MatSlideToggleModule } from '@angular/material/slide-toggle';
// import {
//   SocialLoginModule,
//   SocialAuthServiceConfig,
// } from 'angularx-social-login';
// import { GoogleLoginProvider } from 'angularx-social-login';

// Import library module
import { NgxSpinnerModule } from "ngx-spinner";
import { environment } from '../environments/environment';
import { TermsPopupComponent } from './views/subscription/terms-popup/terms-popup.component';
import { TerminosComponent } from './terminos/terminos.component';

// "client_id":"940790633460-83cef56dp9f9c1jlbvhc6rs19cjaglka.apps.googleusercontent.com"




@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    WineCatalogComponent,
    MenuComponent,
    SubscriptionComponent,
    ProfileComponent,
    MySubscriptionsComponent,
    ProfileMenuComponent,
    AddressesComponent,
    CreditcardsComponent,
    ResetComponent,
    ConfirmationComponent,
    LoginComponent,
    StaticBannerComponent,
    NotFoundComponent,
    UnauthorizedComponent,
    FacPopupComponent,
    TdcPopupComponent,
    CreatePopupComponent,
    EditPopupComponent,
    CreateTdcComponent,
    UpdateSubPopupComponent,
    AddressPopupComponent,
    ValidatePopupComponent,
    RecoverPopupComponent,
    TermsPopupComponent,
    TerminosComponent
  ],
  imports: [
    // SocialLoginModule,
    NgxSpinnerModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatMenuModule,
    MatButtonModule,
    MatInputModule,
    MatIconModule,
    MatDialogModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatSlideToggleModule,
    Ng2TelInputModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-center',
      preventDuplicates: true
    }), // ToastrModule added
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptor,
    multi: true,
  }
  // {
    // provide: 'SocialAuthServiceConfig',
    // useValue: {
    //   autoLogin: false,
    //   providers: [
    //     {
    //       // id: GoogleLoginProvider.PROVIDER_ID,
    //       // provider: new GoogleLoginProvider(environment.clientID),
    //     },
    //   ],
    // } as SocialAuthServiceConfig,
  // }
  ,AuthGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
