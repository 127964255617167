<div class="wine-catalog">
    <div class="wine-box">
      <div class="wine">
          <div class="back-wine"><img alt="" src="assets/image/1.png"></div>
        <p>19 CRIMES RED WINE </p>
    </div>
      <div class="wine">
    <div class="back-wine">  <img alt="" src="assets/image/2.png"></div>
      <p>ABADIA DE SAN CAMPIO ALBARIÑO </p>
    </div>
      <div class="wine">
      <div class="back-wine"><img alt="" src="assets/image/3.png"></div>
      <p>ACHAVAL FERRER QUIMERA </p>
    </div>
      <div class="wine">
    <div class="back-wine">  <img alt="" src="assets/image/4.png"></div>
      <p>1000 STORIES ZINFANDEL BOURBON BARREL AGED </p>
    </div>
      <div class="wine">
    <div class="back-wine">  <img alt="" src="assets/image/5.png"></div>
      <p>Aires De Protos   </p>
    </div>
      <div class="wine">
    <div class="back-wine">  <img alt="" src="assets/image/6.png"></div>
      <p>Aldonzo Brut Rose  </p>
    </div>
      <div class="wine">
      <div class="back-wine"><img alt="" src="assets/image/7.png"></div>
      <p>ALVARO PALACIOS CAMINS DEL PRIORAT  </p>
    </div>
      <div class="wine">
    <div class="back-wine">  <img alt="" src="assets/image/8.png"></div>
      <p>LAGARDE PRIMERAS VIÑAS MALBEC   </p>
    </div>
      </div>
</div>