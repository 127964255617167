import { Component, OnInit , Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {CallService} from '../../../services/call.service';
import {NotificationService} from '../../../services/notification.service';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import {AuthService} from '../../../services/auth.service';
import {SpinnerService} from '../../../services/spinner.service';
import { findPhoneNumbersInText } from 'libphonenumber-js';

@Component({
  selector: 'app-edit-popup',
  templateUrl: './edit-popup.component.html',
  styleUrls: ['./edit-popup.component.css']
})
export class EditPopupComponent implements OnInit {

  provinces: any[]= [] ;
  form: FormGroup ;
  corregimientos: any[]= [] ;

  configuration  = {} ;
  errorNumber = true;
  numberRequired = false;
  options:any;
  phone : any;

  constructor(private spinner : SpinnerService,public auth : AuthService, private notify : NotificationService,private call : CallService, 
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<EditPopupComponent>,) { 

       this.phone = this.data.row.phone;
      

      if( this.data.row.phone !== null){
        let findPhone = findPhoneNumbersInText(this.data.row.phone);
        if(findPhone.length > 0){
          this.phone = findPhone[0].number.nationalNumber;
          this.configuration  = {initialCountry: findPhone[0].number.country ,separateDialCode:true} ;
        }
      }

      this.form = new FormGroup({
        id: new FormControl(this.data.row.id, Validators.required),
        phone: new FormControl(this.data.row.phone, Validators.required),
        adress: new FormControl(this.data.row.adress, Validators.required),
        province : new FormControl(this.data.row.province.id, Validators.required),
        corregimiento : new FormControl(this.data.row.corregimiento == null ? '' : this.data.row.corregimiento.id, Validators.required)
      });  

    }


  ngOnInit(): void {
  this.options = this.configuration;
  this.spinner.open();
  this.call.get(this.call.HOST + "/partner/" + this.auth.getPartner()).subscribe(response =>{
        
    this.provinces = response.result.provinces;
    this.provinces = this.provinces.sort((a,b) => a.name > b.name ? 1 : -1);// ORDENAMOS ALFABETICAMENTE
    if(this.data.row.corregimiento != null){
      this.onProvinceFirstChange(this.form.value.province);
    }
    

    this.spinner.close();
  }, err =>{
    this.spinner.close();
    this.notify.showError(err);
    this.notify.showError(err)
  });
  }

  onProvinceFirstChange(province:any){
    this.corregimientos = [];
    if(this.provinces[this.provinces.findIndex(t => t.id == province)] != null){
      this.corregimientos = this.provinces[this.provinces.findIndex(t => t.id == province)].corregimientos;
    }
  }

  onProvinceChange(province:any){
    this.corregimientos = [];
    this.form.controls.corregimiento.setValue('');
    if(this.provinces[this.provinces.findIndex(t => t.id == province)] != null){
      this.corregimientos = this.provinces[this.provinces.findIndex(t => t.id == province)].corregimientos;
    }
  }

  update(){
    const data = this.form.value;
    data.customer = { id: +this.auth.getCustomer() } ;  
    data.status = 1;
    data.corregimiento = {id: +data.corregimiento}
    data.province = {id: +data.province};

    this.call.put(data, this.call.HOST + "/adress/" + data.id ).subscribe(res =>{
        this.notify.showSuccess("Actualizada correctamente");
        this.close();
    }, err=>{
      this.notify.showError(err);
    });
    
  }
  close(){
    this.dialogRef.close();
  }

  hasError(obj:any){
    // FALSE : ERROR - TRUE -> NO ERROR
    this.errorNumber = obj;
    if(obj){
      this.numberRequired = true;
    }
  }

  onCountryChange(obj:any){
    //console.log(obj);
  }

  telInputObject(obj:any){
    //console.log(obj);
  }

  getNumber(obj:any){
    this.form.controls["phone"].setValue(obj);
  }

}
