<!-- MENU DE LA APP -->
<app-menu></app-menu>

<!--Dashboard user-->
<div class="container">
    
    <app-static-banner></app-static-banner>

<div class="dashboard">
    <div class="column-profile">
        <app-profile-menu></app-profile-menu>
    </div>
<div class="column-data">
    <div class="content-data">
<h1>Configuración de la cuenta</h1>
<p>Edite la configuración de su cuenta y cambie su contraseña aquí.</p>
<form [formGroup]="form" (ngSubmit)="change()" class="form-horizontal">
<fieldset>

<!-- Form Name -->
<legend>Cambiar contraseña</legend>

<!-- Text input-->
<div class="form-group">
    <label class="col-md-4 control-label" for="textinput1">Contraseña actual</label>
    <div class="col-md-4">
    <input [formControl]="form.controls['actual_password']" id="textinput1" name="textinput" type="password" placeholder="Escriba la contraseña" class="form-control input-md">
    <small
                      *ngIf="form.controls['actual_password'].hasError('required') && form.controls['actual_password'].touched" 
                      class="form-error-msg"> Contraseña es requerido </small>
    </div>
</div>

<!-- Text input-->
<div class="form-group">
<label class="col-md-4 control-label" for="textinput2">Nueva contraseña</label>
<div class="col-md-4">
<input  [formControl]="form.controls['new_password']" id="textinput2" name="textinput" type="password" placeholder="Escriba la contraseña" class="form-control input-md">
<small
                        *ngIf="form.controls['new_password'].hasError('minlength') && form.controls['new_password'].touched" 
                        class="form-error-msg"> Nueva contraseña debe tener al menos 6 digitos </small>
                        <small
                        *ngIf="form.controls['new_password'].hasError('required') && form.controls['new_password'].touched" 
                        class="form-error-msg"> Nueva contraseña es requerido </small>
</div>
</div>

<!-- Text input-->
<div class="form-group">
<label class="col-md-4 control-label" for="textinput3">Repita la contraseña</label>
<div class="col-md-4">
<input [formControl]="form.controls['newPasswordConfirm']"  id="textinput3" name="textinput" type="password" placeholder="Escriba la contraseña" class="form-control input-md">
<small
                        *ngIf="form.controls['newPasswordConfirm'].hasError('minlength') && form.controls['newPasswordConfirm'].touched" 
                        class="form-error-msg"> Nueva contraseña debe tener al menos 6 digitos </small>
                        <small
                        *ngIf="form.controls['newPasswordConfirm'].hasError('required') && form.controls['newPasswordConfirm'].touched" 
                        class="form-error-msg"> Contraseña es requerido </small>
</div>
</div>

<!-- Button -->
<div class="form-group">
<label class="col-md-4 control-label" for="send"></label>
<div class="col-md-4">
<button [disabled]="form.invalid" id="send" name="send" class="btn btn-primary">Cambiar</button>
</div>
</div>

</fieldset>
</form>
</div></div>
</div>
</div>