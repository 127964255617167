import { Component, OnInit , Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {CallService} from '../../../services/call.service';
import {NotificationService} from '../../../services/notification.service';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import {AuthService} from '../../../services/auth.service';
import {SpinnerService} from '../../../services/spinner.service';

@Component({
  selector: 'app-create-popup',
  templateUrl: './create-popup.component.html',
  styleUrls: ['./create-popup.component.css']
})
export class CreatePopupComponent implements OnInit {

  provinces: any[]= [] ;
  form: FormGroup ;
  corregimientos: any[]= [] ;

  configuration  = {initialCountry: "PA",separateDialCode:true} ;
  errorNumber = true;
  numberRequired = false;
  options:any;
  phone : any;

  constructor(private spinner : SpinnerService,public auth : AuthService, private notify : NotificationService,private call : CallService, 
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CreatePopupComponent>) { 
      this.form = new FormGroup({
        //lastname: new FormControl('', Validators.required),
        //name: new FormControl('', Validators.required),
        phone: new FormControl('', Validators.required),
        adress: new FormControl('', Validators.required),
        province : new FormControl('', Validators.required),
        corregimiento : new FormControl('', Validators.required)
      });
    }

  ngOnInit(): void {

    this.options = {initialCountry: '',separateDialCode:true,preferredCountries: ['pa']};
    this.spinner.open();
    this.call.get(this.call.HOST + "/partner/" + this.auth.getPartner()).subscribe(response =>{
          
      this.provinces = response.result.provinces;
      this.provinces = this.provinces.sort((a,b) => a.name > b.name ? 1 : -1);// ORDENAMOS ALFABETICAMENTE

      this.spinner.close();
    }, err =>{
      this.spinner.close();
      this.notify.showError(err);
      this.notify.showError(err)
    });

  }

  onProvinceChange(province:any){

    this.corregimientos = [];
    this.corregimientos = this.provinces[this.provinces.findIndex(t => t.id == province)].corregimientos;

  }


  create(){

    const data = this.form.value;
    data.customer = { id: +this.auth.getCustomer()} ;
    data.status = 1;
    data.corregimiento = {id: +data.corregimiento}
    data.province = {id: +data.province };

    this.call.post(data, this.call.HOST + "/adress").subscribe(res =>{
        this.notify.showSuccess("Creada correctamente");
        this.close();
    }, err=>{
      this.notify.showError(err);
    });
    
  }

  close(){
    this.dialogRef.close();
  }


  hasError(obj:any){
    // FALSE : ERROR - TRUE -> NO ERROR
    this.errorNumber = obj;
    if(obj){
      this.numberRequired = true;
    }
  }

  onCountryChange(obj:any){
    //console.log(obj);
  }

  telInputObject(obj:any){
    //console.log(obj);
  }

  getNumber(obj:any){
    this.form.controls["phone"].setValue(obj);
  }

}
