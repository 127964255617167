import {Component, Inject, OnInit } from '@angular/core';
import {CallService} from '../../../services/call.service';
import {NotificationService} from '../../../services/notification.service';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import {AuthService} from '../../../services/auth.service';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FacPopupComponent } from '../../subscription/fac-popup/fac-popup.component';
import { SpinnerService } from 'src/app/services/spinner.service';

@Component({
  selector: 'app-create-tdc',
  templateUrl: './create-tdc.component.html',
  styleUrls: ['./create-tdc.component.css']
})
export class CreateTdcComponent implements OnInit {

  form: FormGroup ;
  items: Array<any> = [];
  creditCardId: any;

  constructor(
    private sanitizer: DomSanitizer,
    private dialog: MatDialog,
    public auth : AuthService, 
    public loader : SpinnerService,
    private notify : NotificationService,
    private call : CallService, 
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CreateTdcComponent>) {

      this.form = new FormGroup({
       
        nameHolderCreditCard : new FormControl('', Validators.required),
        numberCreditCard: new FormControl('', [Validators.required,Validators.pattern(/^[0-9-]*$/), this.validateCreditCardNumber]),
        expMonthCreditCard: new FormControl('', Validators.required),
        expYearCreditCard: new FormControl('', Validators.required),
    
      });
  
   }
// SETUP
ngOnInit(): void {
}


  create(){

    const obj = this.form.value;

    //Se verifica el rango del mes y ano de vencimiento de la TDC
    if (this.verifyCCYearAndMonth(obj.expMonthCreditCard.toString(),obj.expYearCreditCard.toString())) {
      //Se verifica el numero de digitos de la TDC
      if (obj.numberCreditCard.toString().length >= 13 && obj.numberCreditCard.toString().length <= 19) {
        //Se verifica que el numero de la TDC cumpla con el algoritmo de Luhm
        //if (this.valid_credit_card(obj.numberCreditCard.toString())) {
          //En caso de que el numero de tarjeta (ultimos digitos) coincidan con una TDC previamente agregada, pedir confirmacion
            
            obj.estatus = "1";
            const cleanedCreditCardNumber = obj.numberCreditCard.replace(/-/g, '');
            obj.numberCreditCard = cleanedCreditCardNumber.trim();

            // HACEMOS ESTO PARA VALIDAR QUE NO ESTE INGRESANDO UNA TARJETA YA REGISTRADA
            // let found = false;
            // for(let tdc of this.items){
            //   if(tdc.maskCreditCard.substr(tdc.maskCreditCard.length-4) == obj.numberCreditCard.toString().substr(obj.numberCreditCard.toString().length-4) ){
            //     found = true;
            //   }
            // }

            const send = {
              param: 11,
              creditcard: obj,
              customer: this.auth.getCustomer()
            };
            // console.log("CON ESTE OBJETO PROCESAMOS LA TARJETA: ", send)
            this.loader.open();
            this.call.suscribe(send, `${this.call.HOST}/suscription`).subscribe(response => {
              this.handleResponse(response)
            }, err => {
              this.loader.close();
              console.log("ENTRO POR ESTE ERROR")
              this.notify.showError(err.message ?? err);
            });
            
      }
      else {
        this.notify.showError("Verifique el número de la tarjeta. Cantidad de digitos inválida");
      }
    }
    else {
      this.notify.showError("Verifique el año/mes de vencimiento de la tarjeta introducida");
    }
    
  }

  //Metodo que permite verificar el mes y ano de la TDC introducida
  verifyCCYearAndMonth(month : string, year: string) : boolean{
    //Verifica que el mes se encuentre entre 01 y 12
    if(1 < Number.parseInt(month) && Number.parseInt(month) > 12){
      return false;
    }

    //Verifica el limite inferior del ano de vencimiento
    if((Number.parseInt(month)-1) < new Date().getMonth()){
      if(Number.parseInt(year) <= Number.parseInt(new Date().getFullYear().toString().substr(2))){
        return false;
      }
    }
    else{
      if(Number.parseInt(year) < Number.parseInt(new Date().getFullYear().toString().substr(2))){
        return false;
      }
    }

    //Verifica el limite superior del ano de vencimiento
    if(Number.parseInt(year) >= (Number.parseInt(new Date().getFullYear().toString().substr(2))+15)){
        return false;
    }
    return true;
  }

  close(){
    this.dialogRef.close();
  }

  onlyNumberKey(event : any){
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }


// 3DS DINAMICO
private handleResponse(response: any): void {
  console.log("RESPONSE DEL PROCESO 3DS: ", response)
  this.creditCardId = response.id;

  if (response.bank === 'powerTranz') {
    console.log("POWERTRANZ")
    this.creditCardId = response.id;
    this.handlePowerTranzResponse(response);
  } else if (response.bank === 'cyberSource') {
    console.log("CYBERSOURCE")
    // this.handleCyberSourceResponse(response);
  } else if (response.bank === 'emetec') {
    console.log("EMETEC")
    // this.handleEmetecResponse(response);
  } else {
    if(response.message == "Successfull created"){ // LA TARJETA PUEDE ESTAR VALIDA PARA 3DS
      this.creditCardId = response.result;
      this.myLoadEvent();
    }else{
      this.notify.showError("ESTE BANCO NO ESTA REGISTRADO");
    }
  }
}
// TERMINA PROCESO DE 3DS DINAMICO

private handlePowerTranzResponse(response: any): void {
  this.openFacPopup(response.htmlFormData);
}

private openFacPopup(htmlFormData: string): void {
  this.loader.close();
  const dialogRef = this.dialog.open(FacPopupComponent, {
    width: '50%',
    disableClose: true,
    data: { form: this.sanitizer.bypassSecurityTrustHtml(htmlFormData), creditCardId: this.creditCardId }
  });

  dialogRef.afterClosed().subscribe(() => {
    this.myLoadEvent();
  });
}
// TERMINA PROCESO PARA POWERTRANZ


myLoadEvent() {
  this.loader.open();
  console.log("CONSULTANDO: " + this.call.HOST + "/creditcard/" + this.creditCardId)
  this.call.get(this.call.HOST + "/creditcard/" + this.creditCardId).subscribe(chp => {
    this.loader.close();
    if (chp.result.verified == true) { // ESTO ES QUE SE COBRO Y TODO BIEN CON LA SUSCRIPCION ESTA ACTIVA
      this.notify.showSuccess("Tarjeta agregada correctamente");
      this.close();
    } else {
      this.notify.showError("Tarjeta rechazada por el banco, por favor ingrese otra");
      this.deleteCreditCard();
    }
  }, err => {
    this.loader.close();
    this.notify.showError("Tarjeta rechazada por el banco, por favor ingrese otra");
    this.deleteCreditCard();
  });
}


private deleteCreditCard(){
  this.loader.open();
  console.log("HACEMOS EL DELETE DE LA TARJETA con esta URL : " + `${this.call.HOST}/customer/${this.auth.getCustomer()}/creditcard/${this.creditCardId}/delete?useIdentifier=true` )
  this.call.post({}, `${this.call.HOST}/customer/${this.auth.getCustomer()}/creditcard/${this.creditCardId}/delete?useIdentifier=true`).subscribe(fourthResponse => {
    console.log("RESPUESTA DEL DELETE: ", fourthResponse)
    // this.myLoadEvent();
    this.loader.close();
  }, errr =>{
    console.log("ERROR EN EL DELETE: ", errr)
    // this.notify.showError(errr)
    this.loader.close();
  });
}


validateCreditCardNumber = (control: FormControl): { [key: string]: any } | null => {
  const value = control.value.replace(/-/g, ''); // Eliminar guiones para validación
  if (!value) return null;

  // Comprobar si tiene exactamente 16 dígitos
  if (value.length !== 16) {
      return { invalidLength: true };
  }

  // Validar usando el Algoritmo de Luhn
  if (!this.luhnCheck(value)) {  // Asegúrate de que 'this.luhnCheck' está correctamente referenciado.
      return { invalidCardNumber: true };
  }

  return null;
}

// Algoritmo de Luhn para validar el número de tarjeta
luhnCheck(cardNumber: string): boolean {
  let sum = 0;
  let shouldDouble = false;

  for (let i = cardNumber.length - 1; i >= 0; i--) {
    let digit = parseInt(cardNumber.charAt(i), 10);

    if (shouldDouble) {
      digit *= 2;
      if (digit > 9) digit -= 9;
    }

    sum += digit;
    shouldDouble = !shouldDouble;
  }

  return (sum % 10) === 0;
}

formatCreditCardNumber(): void {
  const control = this.form.get('numberCreditCard');
  let value = control?.value.replace(/[^0-9]/g, ''); // Eliminar caracteres no numéricos

  if (value.length > 16) {
    value = value.substring(0, 16); // Limitar a 16 caracteres
  }

  // Agregar guiones automáticamente cada 4 dígitos
  const formattedValue = value.match(/.{1,4}/g)?.join('-') || value;
  control?.setValue(formattedValue, { emitEvent: false }); // Actualizar el valor del campo sin emitir eventos adicionales
}


}
