import { Injectable } from "@angular/core";
import {AuthService} from '../services/auth.service';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
//import { JwtAuthService } from "../services/auth/jwt-auth.service";

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private auth : AuthService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
   
 /* let time = this.auth.getTime();
  let timeLoggin = new Date(time); // TIEMPO EN EL QUE VENCE LA SESION DEL USUARIO
  let timeActual = new Date(); // HORA ACTUAL
  console.log(timeActual);
  console.log(timeLoggin);
  let permission = true; // EN PRINCPIO CONCEDEMOS EL PERMISO
  if(this.auth.getTime() != null){ // ESTO EN CASO DE QUE EL USUARIO YA HAYA INICIADO SESION
      if(timeLoggin <= timeActual || timeActual.getFullYear() != timeLoggin.getFullYear()){ // COMPARAMOS LA FECHAS
        permission = false;
      }
  }*/

    if (this.auth.isLoggedIn()) { // LO DEJAMOS AVANZAR SI ESTA LOGEADO Y SI NO SE LE HA VENCIDO LA SESION, TIENE PERMISO DE ACANZAR
      return true;
    } else { // CASO CONTRARIO LO MANDAMOS A INICIAR SESION NUEVAMENTE
      localStorage.removeItem(this.auth.APP_USER_TIME);
      this.router.navigate(["/unauthorized"], { 
        queryParams: {
          return: state.url
        }
      });
      return false;
    }

  }
}
