import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import {CallService} from '../../services/call.service';
import {AuthService} from '../../services/auth.service';
import {NotificationService} from '../../services/notification.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  form: FormGroup ;

  constructor(private call : CallService,private auth : AuthService, private notify : NotificationService) { 

    this.form = new FormGroup({
      id : new FormControl('',Validators.required),
      status_customer : new FormControl('',Validators.required),
      name_customer: new FormControl('', Validators.required),
      lastName_customer: new FormControl('', Validators.required),
      identifier_customer: new FormControl('', Validators.required),
      email_customer: new FormControl('', [Validators.required,Validators.email])
      //phoneNumber_customer:new FormControl(''),
    });

  }

  ngOnInit(): void {
    this.getInfo();
  }

  getInfo(){
    this.call.get(this.call.HOST + '/ecommerce/customer/' + this.auth.getCustomer()).subscribe(customer =>{

      this.form.controls["id"].setValue(customer.result.id);
      this.form.controls["status_customer"].setValue(customer.result.status_customer);
      this.form.controls["name_customer"].setValue(customer.result.name_customer);
      this.form.controls["lastName_customer"].setValue(customer.result.lastName_customer);
      this.form.controls["identifier_customer"].setValue(customer.result.identifier_customer);
      this.form.controls["email_customer"].setValue(customer.result.email_customer);

    }, err =>{
      this.notify.showError(err);
    })
  }

  update(){

    const form = this.form.value;
    // COMO ES CLIENTE DE LANDING, SU EMAIL SERA EL CODIGO DEL CLIENTE
    form.code_customer = form.email_customer;

    this.call.get(this.call.HOST + "/ecommerce/service/" + this.auth.getService()).subscribe(response =>{

      // ESTE ES EL PARTNER QUE ES DUE*O DEL PLAN Y SERVICIO
      form.partner = {id:response.result.partner_id};
      this.call.put(form, this.call.HOST + "/ecommerce/customer/" + form.id).subscribe( res =>{

        this.notify.showSuccess("Se ha actualizado el perfil correctamente");
        this.getInfo();

      }, err=>{
        this.notify.showError(err);
      })

    }, err =>{
      this.notify.showError(err);
    })

  }

}
