
    <div style="text-align: end;" mat-dialog-title >
        <button  mat-raised-button mat-icon-button  class="mb-8" color="warn" (click)="close(3)"><mat-icon>close</mat-icon></button>
    </div>

    <div  style="width: 100%;" class="column-data">
        <form  [formGroup]="form"  >
            <fieldset>

                <!-- Form Name -->
                <legend>Actualizar </legend>


                <!-- Text input-->
                <div class="two-column-form">
                    <div class="form-group field-tc">
                        <label class="text-dark">Producto:</label>
                        <input  readonly  [formControl]="form.controls['product']" type="text" placeholder="Producto" class="form-control one-c" name="tc" access="false" id="tc" required="required" aria-required="true">

                    </div>
                    <div class="form-group field-Numero-de-Tarjeta">
                        <label class="text-dark">Precio:</label>
                        <input  readonly  [formControl]="form.controls['price']" type="text" placeholder="Precio" class="form-control one-c" name="Numero-de-Tarjeta" access="false" id="Numero-de-Tarjeta" required="required" aria-required="true">
                    </div>
                </div>


                 <!-- Text input-->
                 <div class="two-column-form">
                    <div class="form-group field-tc">
                        <label class="text-dark">Frecuencia de cobro:</label>
                        <input  readonly [formControl]="form.controls['frecuency']" type="text" placeholder="Frecuencia" class="form-control one-c" name="tc" access="false" id="tc" required="required" aria-required="true">

                    </div>
                    <div class="form-group field-Numero-de-Tarjeta">
                            <label class="text-dark">Próximo cobro:</label>
                            <input readonly  [formControl]="form.controls['next']"  type="text" placeholder="Proximo cobro" class="form-control one-c" name="Numero-de-Tarjeta" access="false" id="Numero-de-Tarjeta" required="required" aria-required="true">
                    </div>
                </div>

                <div class="row">

                    <div class="form-control">
                      <div  style="text-align: center;">
                        <label class="text-dark">Dirección de envío</label>
                      </div>
                          <div style="text-align: end;">
                            <button  mat-raised-button class="mb-8" color="primary" (click)="addressesPopUp({})" >Cambiar dirección</button>
                          </div>
                    </div>


                  </div>

                <!-- Text input-->
               <div class="two-column-form">
                    <div class="form-group field-tc">
                        <label class="text-dark">Provincia:</label>
                        <input readonly [formControl]="form.controls['province']" type="text" placeholder="Provincia" class="form-control one-c" name="tc" access="false" id="tc" required="required" aria-required="true">

                    </div>
                    <!-- ESTO SE DEBE QUITAR CUANDO SEA AGREGUEN LOS CORREGEMIENTOS - HABILITAR EL DE ABAJO -->
                    <div class="form-group field-Numero-de-Tarjeta">
                        <label class="text-dark">Direccion:</label>
                        <input readonly [formControl]="form.controls['adress']" type="text" placeholder="Dirección" class="form-control one-c" name="direccion" access="false" id="direccion" required="required" aria-required="true">
                    </div>

                  <!--   <div class="form-group field-Numero-de-Tarjeta">
                        <label class="text-dark">Corregimiento:</label>
                            <input  [formControl]="form.controls['corregimiento']"  type="text" placeholder="Corregmiento" class="form-control one-c" name="Numero-de-Tarjeta" access="false" id="Numero-de-Tarjeta" required="required" aria-required="true">
                    </div>-->
                </div>


             <!--    <div class="form-group-solo field-direccion">
                    <label class="text-dark">Direccion:</label>
                    <input style="width: 96%;" [formControl]="form.controls['adress']" type="text" placeholder="Dirección" class="form-control one-c" name="direccion" access="false" id="direccion" required="required" aria-required="true">
                </div>  -->


                <div style="padding-top: 20px;"  class="row">

                    <div class="form-control">
                      <div  style="text-align: center;">
                        <label class="text-dark">Datos de cobro</label>
                      </div>
                          <div style="text-align: end;">
                            <button  mat-raised-button class="mb-8" color="primary" (click)="tdcPopUp({})" >Cambiar tarjeta</button>
                          </div>
                    </div>


                  </div>

                <!-- Text input-->
                <div class="two-column-form">
                    <div class="form-group field-tc">
                        <label class="text-dark">Titular:</label>
                        <input readonly  [formControl]="form.controls['name_tdc']" type="text" placeholder="Nombre" class="form-control one-c" name="tc" access="false" id="tc" required="required" aria-required="true">

                    </div>
                    <div class="form-group field-Numero-de-Tarjeta">
                        <label class="text-dark">Tarjeta:</label>
                            <input  readonly [formControl]="form.controls['number_tdc']"  type="text" placeholder="Tarjeta" class="form-control one-c" name="Numero-de-Tarjeta" access="false" id="Numero-de-Tarjeta" required="required" aria-required="true">
                    </div>
                </div>

                <!-- Text input
                <div class="two-column-form">
                    <div class="form-group field-tc">
                        <label class="text-dark">Mes:</label>
                        <input  [formControl]="form.controls['month']" type="text" placeholder="Nombre" class="form-control one-c" name="tc" access="false" id="tc" required="required" aria-required="true">

                    </div>
                    <div class="form-group field-Numero-de-Tarjeta">
                        <label class="text-dark">Año:</label>
                            <input  [formControl]="form.controls['year']"  type="text" placeholder="Tarjeta" class="form-control one-c" name="Numero-de-Tarjeta" access="false" id="Numero-de-Tarjeta" required="required" aria-required="true">
                    </div>
                </div> -->


            <!-- Button -->

            <div *ngIf="data.sub.status == 3" style="text-align: end;">
                <a class="text-dark" type="button" style="cursor: pointer;" (click)="validateActivatePopUp({})" > Activar suscripcion</a>
            </div>

            <div *ngIf="data.sub.status == 1" style="text-align: end;">
                <a style="display: block;" class="text-dark" type="button" style="cursor: pointer;" (click)="validatePausePopUp({})" > Pausar suscripcion</a>
            </div>
            <div *ngIf="data.sub.status != 2" style="text-align: end;">
              <a class="text-dark" type="button" style="cursor: pointer;" (click)="validateCancelPopUp({})" > Cancelar suscripcion</a>
            </div>

            <div   style="text-align: start; padding-top: 20px;" class="example-button-row">

                <button  mat-raised-button class="mb-8" (click)="edit()" color="basic">Actualizar</button>

            </div>

            </fieldset>
        </form>


    </div>
