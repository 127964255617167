import { Component, OnInit , Inject} from '@angular/core';
import {CallService} from '../../../services/call.service';
import {NotificationService} from '../../../services/notification.service';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import {AuthService} from '../../../services/auth.service';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AddressPopupComponent } from '../../popups/address-popup/address-popup.component';
import { TdcPopupComponent } from '../../popups/tdc-popup/tdc-popup.component';
import { ValidatePopupComponent } from './validate-popup/validate-popup.component';
import {SpinnerService} from '../../../services/spinner.service';


@Component({
  selector: 'app-update-sub-popup',
  templateUrl: './update-sub-popup.component.html',
  styleUrls: ['./update-sub-popup.component.css']
})
export class UpdateSubPopupComponent implements OnInit {

  form: FormGroup ;
  tarjetas : any = [];
  items: any = [];
  adress : any = null;
  tdc: any = null;

  constructor(private spinner : SpinnerService, private dialog: MatDialog,public auth : AuthService, private notify : NotificationService,private call : CallService, 
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<UpdateSubPopupComponent>) { 

      this.form = new FormGroup({
        id: new FormControl(this.data.sub.id, Validators.required),
        product: new FormControl(this.data.sub.plan.title, Validators.required),
        price: new FormControl('$'+this.data.sub.plan.feeCost_plan, Validators.required),
        frecuency: new FormControl(this.data.sub.plan.frecuency.name_frecuency),
        next: new FormControl(this.data.sub.next_bill_date, Validators.required),
        adress: new FormControl('', Validators.required),
        name_tdc: new FormControl('', Validators.required),
        number_tdc: new FormControl('', Validators.required),
        month: new FormControl('', Validators.required),
        year: new FormControl('', Validators.required),
        province : new FormControl('', Validators.required),
        corregimiento : new FormControl('', Validators.required)
      });
      

    }

  ngOnInit(): void {

    this.spinner.open();

    // cargamos la informacion de la direccion asociada a la suscripcion
    this.call.get(this.call.HOST + "/adress/" + this.data.sub.adress_id).subscribe( response =>{

      this.form.controls["adress"].setValue(response.result.adress);
      if(response.result.province != null){
        this.form.controls["province"].setValue(response.result.province.name);
        //this.form.controls["corregimiento"].setValue(response.result.corregimiento.name);
      }

      // Buscamos la tarjeta del usuario
      this.call.get(this.call.HOST + '/customer/' + this.auth.getCustomer() + '/creditcard').subscribe(response=>{

        this.tarjetas = response.result;

        let t = this.tarjetas[this.tarjetas.findIndex((t:{ tokenCreditCard: any; }) => t.tokenCreditCard == this.data.sub.creditCardToken.token_creditCardToken)];
        this.form.controls["number_tdc"].setValue(t.creditCard.maskCreditCard);
        this.form.controls["name_tdc"].setValue(t.creditCard.nameHolderCreditCard);

        // para el mes 
        let month;
        switch(t.creditCard.yearMonthExpiration.substr(t.creditCard.yearMonthExpiration.length - 2)){
          case "01" : month = "ENERO";
          break;
          case "02" : month = "FEBRERO";
          break;
          case "03" : month = "MARZO";  
          break;
          case "04" : month = "ABRIL";  
          break;
          case "05" : month = "MAYO";  
          break;
          case "06" : month = "JUNIO";  
          break;
          case "07" : month = "JULIO";  
          break;
          case "08" : month = "AGOSTO";  
          break;
          case "09" : month = "SEPTIEMBRE";  
          break;
          case "10" : month = "OCTUBRE";  
          break;
          case "11" : month = "NOVIEMBRE";  
          break;
          default:
            month = "DICIEMBRE";
          
        }


        this.form.controls["year"].setValue("20" + t.creditCard.expYearCreditCard);
        this.form.controls["month"].setValue(month);
        this.spinner.close();
      })

    });

  }

  close(param : any){
    this.dialogRef.close(param);
  }

  edit(){

    this.spinner.open();

    if(this.adress != null || this.tdc != null){
      const data: any = {} //this.formEdit.value;
      data.customer = this.auth.getCustomer()
      if(this.adress != null){
        data.adress = {id:this.adress} ;
      }
      
      if( this.tdc != null){
        data.tdc = {id:this.tdc}
      }
      
     data.hasPlan = this.form.value.id;
     this.call.put(data,this.call.HOST + "/ecommerce/hasplan/" + data.hasPlan).subscribe(response =>{
        this.notify.showSuccess("Actualizada correctamente")
        this.close(4);
        this.spinner.close();
  
      }, err =>{
        console.log(err);
        this.spinner.close();
        this.notify.showError(err);
      })
    }else{
      this.spinner.close();
      this.notify.showInfo("Debe seleccionar una direccion de envío o una tarjeta distinta a la actual") 
    }
    
  }


  pause(){
    this.call.put({action : 3},this.call.HOST + "/ecommerce/hasplan/" + this.form.value.id + "/status" ).subscribe(response =>{
      
       this.close(1);
    }, (err) =>{
      this.notify.showError(err);
    })
  }

  activate(){
    this.call.put({action : 1},this.call.HOST + "/ecommerce/hasplan/" + this.form.value.id + "/status" ).subscribe(response =>{
      
       this.close(0);

    }, (err) =>{
      this.notify.showError(err);
    })
  }

  cancel(){
    
    this.call.put({action : 2},this.call.HOST + "/ecommerce/hasplan/" + this.form.value.id + "/status" ).subscribe(response =>{
      this.notify.showSuccess("Suscripción cancelada correctamente");
      this.close(2);
    }, (err) =>{
     this.notify.showError(err)
    })
  }

  addressesPopUp(data: any = {}) {
    
    let dialogRef: MatDialogRef<any> = this.dialog.open(AddressPopupComponent, {
      width: '50%',
      height: '60%',
      disableClose: true,
      data: {  }
    })
    dialogRef.afterClosed()
      .subscribe(res => {
        if(!res) {
          // If user press cancel
          return;
        }

        console.log(res);
        // TOMAMOS LA INFORMACION DE LA DIRECCION SELECCIONADA
        this.adress = res.id;
        this.form.controls["adress"].setValue(res.adress);
       
        this.form.controls["province"].setValue(res.province.name);
        this.form.controls["corregimiento"].setValue(res.corregimiento != null ? res.corregimiento.name : null);
       
      })
  }

  tdcPopUp(data: any = {}) {
    
    let dialogRef: MatDialogRef<any> = this.dialog.open(TdcPopupComponent, {
      width: '50%',
      height: '60%',
      disableClose: true,
      data: { }
    })
    dialogRef.afterClosed()
      .subscribe(res => {
        if(!res) {
          // If user press cancel
          return;
        }
       // console.log(res.token);
        this.tdc = res.token;
        this.form.controls["name_tdc"].setValue(res.nameHolderCreditCard);
        this.form.controls["number_tdc"].setValue(res.maskCreditCard);
         // para el mes 
         let month;
         switch(res.yearMonthExpiration.substr(res.yearMonthExpiration.length - 2)){
           case "01" : month = "ENERO";
           break;
           case "02" : month = "FEBRERO";
           break;
           case "03" : month = "MARZO";  
           break;
           case "04" : month = "ABRIL";  
           break;
           case "05" : month = "MAYO";  
           break;
           case "06" : month = "JUNIO";  
           break;
           case "07" : month = "JULIO";  
           break;
           case "08" : month = "AGOSTO";  
           break;
           case "09" : month = "SEPTIEMBRE";  
           break;
           case "10" : month = "OCTUBRE";  
           break;
           case "11" : month = "NOVIEMBRE";  
           break;
           case "12" : month = "DICIEMBRE";  
           break;
         }
 
 
         this.form.controls["year"].setValue(res.expYearCreditCard);
         this.form.controls["month"].setValue(month);
       
      })
  }

  validatePausePopUp(data: any = {}) {
    
    let dialogRef: MatDialogRef<any> = this.dialog.open(ValidatePopupComponent, {
      width: '50%',
      height: '60%',
      disableClose: true,
      data: { action: "Pausar" }
    })
    dialogRef.afterClosed()
      .subscribe(res => {
        console.log(res);
        if(!res) {
          // If user press cancel
          return;
        }
        // TOMAMOS LA INFORMACION DE LA DIRECCION SELECCIONADA
        if(res){
          this.pause();
        }
       
      })
  }
  

  validateActivatePopUp(data: any = {}) {
    
    let dialogRef: MatDialogRef<any> = this.dialog.open(ValidatePopupComponent, {
      width: '50%',
      height: '60%',
      disableClose: true,
      data: { action: "Activar" }
    })
    dialogRef.afterClosed()
      .subscribe(res => {
        console.log(res);
        if(!res) {
          // If user press cancel
          return;
        }
        // TOMAMOS LA INFORMACION DE LA DIRECCION SELECCIONADA
        if(res){
          this.activate();
        }
       
      })
  }


  validateCancelPopUp(data: any = {}) {
    
    let dialogRef: MatDialogRef<any> = this.dialog.open(ValidatePopupComponent, {
      width: '50%',
      height: '60%',
      disableClose: true,
      data: { action: "Cancelar" }
    })
    dialogRef.afterClosed()
      .subscribe(res => {
        console.log(res);
        if(!res) {
          // If user press cancel
          return;
        }
        // TOMAMOS LA INFORMACION DE LA DIRECCION SELECCIONADA
        if(res){
          this.cancel();
        }
       
      })
  }

}
